import React from 'react';
import Helmet from 'react-helmet';
import config from '../../config/website';

const SEO = props => {
  const title = config.siteTitle;
  const description = config.siteDescription;
  const realPrefix = config.pathPrefix === '/' ? '' : config.pathPrefix;
  const image = config.siteUrl + realPrefix + config.siteLogo;
  const blogURL = config.siteUrl + config.pathPrefix;
  const schemaOrgJSONLD = [
    {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url: blogURL,
      name: title,
      alternateName: config.siteTitleAlt ? config.siteTitleAlt : '',
    },
  ];
  return (
    <Helmet>
      <html lang={config.siteLanguage} />
      <title>{props.meta_title ? `${props.meta_title} | ${title}` : `${props.page_title} | ${title}`}</title>
      <meta name="msapplication-TileColor" content={config.backgroundColor} />
      <meta name="msapplication-config" content="browserconfig.xml" />
      <meta name="description" content={props.meta_description ? `${props.meta_description}` : `${description}`} />
      <meta name="image" content={props.hero_image ? `${props.hero_image}` : null} />
      <script type="application/ld+json">{JSON.stringify(schemaOrgJSONLD)}</script>
      <meta property="og:locale" content={config.ogLanguage} />
      <meta property="og:site_name" content={config.ogSiteName} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={props.hero_image ? `${props.hero_image}` : null} />
      <meta property="fb:app_id" content={config.siteFBAppID ? config.siteFBAppID : ''} />
    </Helmet>
  );
};

export default SEO;
